@import url("https://fonts.googleapis.com/css?family=Catamaran|Crete+Round&display=swap");
$titleFont: "Crete round", sans-serif;
$baseFont: "Catamaran", sans-serif;


.toastr {
  position: absolute;
  margin: 0 auto;
  max-width: 320px;
  width: 90%;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 10px 0;
  text-align: center;
  background: #D8523D;
  color: #ffffff;
  border-radius: 10px;
  font-family: $baseFont;
}
