@import url("https://fonts.googleapis.com/css?family=Catamaran|Crete+Round&display=swap");
$titleFont: "Crete round", sans-serif;
$baseFont: "Catamaran", sans-serif;

.success {
  width: 320px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 100vh;
  &__title {
    font-size: 26px;
    font-family: $titleFont;
    color: #014556;
    font-weight: normal;
    margin-bottom: 10px;
  }
  &__text {
    font-family: $baseFont;
    font-size: 18px;
    color: #014556;
    line-height: 26px;
  }
  &__button {
    margin: 20px auto;
    background: #0ba489;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-family: $baseFont;
    font-size: 15px;
    font-weight: 600;
    width: 215px;
    height: 50px;
  }
}
