@import url(https://fonts.googleapis.com/css?family=Catamaran|Crete+Round&display=swap);
@import url(https://fonts.googleapis.com/css?family=Catamaran|Crete+Round&display=swap);
@import url(https://fonts.googleapis.com/css?family=Catamaran|Crete+Round&display=swap);
.styles_form__3iy-0 {
  max-width: 320px;
  margin: 38px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-self: center;
  height: 100vh;
  padding: 0 20px; }
  .styles_form__title__34azx {
    font-size: 26px;
    font-family: "Crete round", sans-serif;
    color: #014556;
    font-weight: normal;
    margin-bottom: 32px; }
  .styles_form__inputBox__jEQED {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    flex-direction: column; }
  .styles_form__label__3mGUB {
    font-family: "Catamaran", sans-serif;
    font-size: 15px;
    font-weight: bold;
    color: #6f8186;
    line-height: 26px; }
  .styles_form__input__QE6B2 {
    padding: 10px 15px;
    border: 1px solid #d8ddde;
    border-radius: 5px;
    font-family: "Catamaran", sans-serif;
    font-size: 16px; }
  .styles_form__text__2nYBB {
    font-family: "Catamaran", sans-serif;
    font-size: 15px;
    color: #014556;
    line-height: 19px; }
  .styles_form__textError__1GIZh {
    color: #d8523d; }
  .styles_form__resetBtn__15q3y {
    width: 100%;
    border-radius: 5px;
    padding: 12px;
    background: #0ba489;
    border: none;
    color: #ffffff;
    font-family: "Catamaran", sans-serif;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.75px;
    margin-top: 30px; }
  .styles_form__resetBtndisabled__oTOYd {
    background: #d8ddde;
    color: #6f8186; }

.styles_toastr__1oD7t {
  position: absolute;
  margin: 0 auto;
  max-width: 320px;
  width: 90%;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  padding: 10px 0;
  text-align: center;
  background: #D8523D;
  color: #ffffff;
  border-radius: 10px;
  font-family: "Catamaran", sans-serif; }

.styles_success__2ocro {
  width: 320px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 100vh; }
  .styles_success__title__10OPA {
    font-size: 26px;
    font-family: "Crete round", sans-serif;
    color: #014556;
    font-weight: normal;
    margin-bottom: 10px; }
  .styles_success__text__3_hHd {
    font-family: "Catamaran", sans-serif;
    font-size: 18px;
    color: #014556;
    line-height: 26px; }
  .styles_success__button__2toUl {
    margin: 20px auto;
    background: #0ba489;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-family: "Catamaran", sans-serif;
    font-size: 15px;
    font-weight: 600;
    width: 215px;
    height: 50px; }

