@import url("https://fonts.googleapis.com/css?family=Catamaran|Crete+Round&display=swap");
$titleFont: "Crete round", sans-serif;
$baseFont: "Catamaran", sans-serif;

.form {
  max-width: 320px;
  margin: 38px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-self: center;
  height: 100vh;
  padding: 0 20px;
  &__title {
    font-size: 26px;
    font-family: $titleFont;
    color: #014556;
    font-weight: normal;
    margin-bottom: 32px;
  }
  &__inputBox {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
  }
  &__label {
    font-family: $baseFont;
    font-size: 15px;
    font-weight: bold;
    color: #6f8186;
    line-height: 26px;
  }
  &__input {
    padding: 10px 15px;
    border: 1px solid #d8ddde;
    border-radius: 5px;
    font-family: $baseFont;
    font-size: 16px;
  }
  &__text {
    font-family: $baseFont;
    font-size: 15px;
    color: #014556;
    line-height: 19px;
  }
  &__textError {
    color: #d8523d;
  }
  &__resetBtn {
    width: 100%;
    border-radius: 5px;
    padding: 12px;
    background: #0ba489;
    border: none;
    color: #ffffff;
    font-family: $baseFont;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.75px;
    margin-top: 30px;
  }
  &__resetBtndisabled {
    background: #d8ddde;
    color: #6f8186;
  }
}
